// import * as JsBarcode from 'jsbarcode';
import * as _qrCode from 'qrcode';

export class Qrcode {

	static instance(win = window) {
		let inst = Qrcode._instance;
		if (!inst) {
			inst = Qrcode._instance = new Qrcode();
		}
		return inst;
	}
	static _instance: Qrcode;

	static config: _qrCode.QRCodeRenderersOptions = {

	};

	toCanvas(value: string, config = {}) {
		const canvas = document.createElement('canvas');
		_qrCode.toCanvas(canvas, value, { ... Qrcode.config, ... config});
		return canvas;
	}
	
}