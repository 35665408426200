export interface SettingRow {
	id?: number;
	key: string;
	val: string;
	public?: number;
}

export enum SETTINGS {
	COMPANY_NAME = 'company_name',
	LOGIN_CSS = 'login_css',
}