import { Api } from './Api';

export * from './Auth';
export * from './Api';
export * from './Client';
export * from './Login';
export * from './Notify';
export * from './Setting';
export * from './User';

(window as any).Api = Api;