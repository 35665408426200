import { SettingRow } from "./Setting";
import { ClientRow } from "./Client";
import { AdminObject } from "./Admin";

export interface AuthRow {
	'2FA'?: string;
	'2FA_code'?: string;
	email: string;
	expire?: Date;
	expire_password?: Date;
	first_name: string;
	guest?: boolean;
	id: number;
	last_name: string;
	login: string;
	mobile_phone?: string;
	modified?: Date;
	passhas?: string;
	spoke?: string;
	sysadmin?: boolean;
	token?: string;
	verified?: Date; // is this correct?
}

export class Auth implements AuthRow {

	id: number;
	last_name: string;
	first_name: string;
	email: string;
	login: string;

	company: string;

	guest = false;
	sysadmin = false;

	settings = new Map<string, any>();

	constructor(
		row: AuthRow,
		settings: SettingRow[],
		client: ClientRow,
		public readonly admin: AdminObject,
		public hitched?: any,
		public readonly assumed?: boolean,
	) {	
		if (!row) {
			throw 'invalid_user';
		}
		this.id = row.id;
		this.last_name = row.last_name;
		this.first_name = row.first_name;
		this.email = row.email;
		this.login = row.login;

		this.guest = !!row.guest;
		this.sysadmin = !!row.sysadmin;

		this.company = client.name;

		this.loadSettings(settings);
	}

	loadSettings(rows: SettingRow[] = []) {
		this.settings = new Map(rows.map(row => {
			let val: any;
			try {
				val = JSON.parse(row.val);
			} catch (e) {
				val = row.val;
			}
			return [row.key, val];
		}));
	}

	setting<T>(key: string): T {
		return this.settings.get(key);
	}

}