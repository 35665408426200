import { Observable, from, of, BehaviorSubject, iif, ReplaySubject, defer, forkJoin, zip } from 'rxjs';
import { map, switchMap, catchError, tap, filter, take, timeout, startWith } from 'rxjs/operators';
import { AuthRow, Auth } from './Auth';
import { UserRow } from './User';
import { SettingRow } from './Setting';
import { Login } from './Login';
import { ClientRow } from './Client';
import { Notify } from './Notify';
import { AdminObject, ADMIN_KEYS } from './Admin';
import { Mobile } from './Mobile';
import { AuxiliumSocketClient } from './socket/Socket';
import { Barcode } from './Barcode';
import { Qrcode } from './Qrcode';

// DO NOT export this
const fileUploadKey = 'file';

export interface HitchedRequest {
	id: string;
	request: any;
}

export type HitchedRequests = HitchedRequest[];

export interface ApiConfig {
	allowGuest?: boolean;
	autoAuthenticate?: boolean;
	fileUrl?: string; /* defaults to https://api.datalynk.ca/file */
	hitchWithAuthenticate?: HitchedRequests;
	origin?: string; // DEPRECATED
	spoke?: string;
	uploadUrl?: string; /* defaults to https://api.datalynk.ca/upload */
	url?: string; /* defaults to https://api.datalynk.ca */
	socketUrl?: string; /* defaults to https://datalynk.auxiliumgroup.com:9394/ */
	settingsUrl?: string;  /* defaults to https://api.datalynk.ca/settings */
	preventSocketAutoConnect?: boolean;
}

export interface ValidLogin {
	token: string;
}

export interface ApiRequestOptions {
	url?: string;
	spoke?: string;
	token?: string;
	labelAs?: string;
	ignoreGuestCheck?: boolean;
}

export interface ApiRequestBody {
	spoke: string;
	token?: string;
	req: string;
	socket?: string;
	origin?: string; // DEPRECATED
}

export interface ApiRequestResponse {
	error?: string;
	$prompt?: {args: {token: string}};
	'debug-trace'?: any;
	'debug-log'?: any;
}

// export interface UploadResponse {
// 	progress: Subject<number>;
// 	id: BehaviorSubject<number>;
// 	file: File;
// 	error: string;
// }

export interface ApiTransactionRequestResponse {
	tx?: number;
	publish?: number;
	keys?: number[];
}

export interface ApiXDeleteResponse extends ApiTransactionRequestResponse{
	ignored?: number[];
}

export interface ApiXInsertResponse extends ApiTransactionRequestResponse {

}

export interface ApiXUpdateResponse extends ApiTransactionRequestResponse {
	granted?: {
		[key: number]: {
			[field: string]: number; // the slice that granted it?
		}
	}
}

export interface ApiTransactionResponse {
	success: boolean;
	keys: number[];
	transaction?: number;
}

export enum RecordRequest {
	'insert' = 'xinsert',
	'update' = 'xupdate',
	'delete' = 'xdelete',
	'query' = 'query',
	'report' = 'report',
	'perms' = 'xperms',
}

export interface ApiReportResponse<T> extends ApiRequestResponse {
	rows?: T[];
	query?: string;
	count?: number;
	files?: ApiFilenameResponse;
}

export interface ApiXPermsResponse {
	delete?: number[];
	insert?: {[field: string]: number[]},
	update?: {
		[pkey: number]: {
			[field: string]: number[]
		}
	}
}

export interface PermBits {
	_updatable: Set<string>;
	_deletable: boolean;
}

export interface AssociateUpload {
	slice: number;
	record: number;
	field: string;
	ids?: number[];
}


export interface ApiFilenameResponse {
	[id: number]: {
		id: number;
		name: string;
		extension: string;
		ownser: number;
		created: Date;
		modified: Date;
		size: number;
	}
}

export interface iHeaders {
	[key: string]: string;
}

export interface UploadedFileError {
	error: any;
}
export interface UploadedFileSuccess {
	id: number;
	name: string;
	size: number;
	extension: string;
	mime: string;
	url?: string;
}
export type UploadedFile = UploadedFileError | UploadedFileSuccess;



export interface FilesResponse {
	[fileUploadKey]: UploadedFile;
}


// this shouldn't be exposed out
export interface UploadResponse {
	files: FilesResponse;
}

export interface AssociateResponse {
	removed?: {
		affected: number;
	},
	insert?: {
		id: number,
		affected: number;
		tx: number;
	},
	files: UploadedFileSuccess[];
}

export interface LoginError {
	error: any;

}
// private interfaces
interface ApiRescueSms {
	status: number;
}
interface ApiChangePassFromRecovery {
	token: string;
}

function _defaultDateToJson(): string {
	return this.toISOString();
}

function _customDateToJson(): string {
	return `${this.getFullYear()}/${this.getMonth()+1}/${this.getDate()} ${this.getHours()}:${this.getMinutes()}:${this.getSeconds()}`;
}

function makeDateFromServerJson(param: string): Date | null {
	try {
		if (param) {
			const chunks = (param || '').split(' '),
				d = chunks[0].split('-').map(x => +x),
				t = chunks[1].split(':').map(x => +x);
			return new Date(d[0], d[1] - 1 || 0, d[2], t[0] || 0, t[1] || 0, t[2] || 0, t[3] || 0);
		}
	} catch (err) { }
	return null;
}

/**
 * the server responds with 23:48:22 standard, so, no need for fun stuff, just a null check
 */
function makeTimeFromServerJson(param: string): string | null {
	if (param) {
		return param;
	}
	return null;
}

export class Api {

	static conversions = new Map<string, (val: any) => Date | string>([
		['$/tools/date', (val: any) => makeDateFromServerJson(val['$/tools/date']) ],
		['$/tools/date_strict', (val: any) => makeDateFromServerJson(val['$/tools/date_strict'])],
		['$/tools/time_strict', (val: any) => makeTimeFromServerJson(val['$/tools/time_strict'])],
	]);
	static defaultHeaders: iHeaders = {
		'Content-Type': 'application/json; charset=utf-8',
	};
	static defaultUrl = `https://api.datalynk.ca`;
	static defaultUploadUrl = `https://api.datalynk.ca/upload`;
	static defaultFileUrl = `https://api.datalynk.ca/file`;
	static defaultSettingsUrl = `https://api.datalynk.ca/settings`;
	static defaultSocketUrl = 'https://SPOKE.auxiliumgroup.com:9394';
	static tokenHeader = 'Authorization';
	static spokeHeader = 'Realm';
	static socketHeader = 'socketId';
	static loginEndpoint = 'login';
	static logoutEndpoint = 'logout';
	static guestEndpoint = 'guest';
	static settingsEndpoint = 'settings';

	static formatTokenForHeader(token: string) {
		return `Bearer ${token}`;
	}

	private static instance: Api;
	private static _timezoneKey = 'api.timezone';

	static call(req: any, cb?: (... args: any[]) => void) {
		const i = Api.instance;
		if (!i) {
			console.warn('api has not been instantiated');
		} else {
			cb = cb || ((...args: any[]) => {
				console.log.apply(this, args);
			});
			i.request(req, {labelAs: 'api.debug'}).subscribe(cb);
		}
	}

	private _auth = new BehaviorSubject<Auth>(null);
	auth = this._auth.pipe(filter(a => !!a));
	socket: AuxiliumSocketClient;

	token = new ReplaySubject<string>();
	hitchWithAuthenticate: HitchedRequests;

	// readonly errors = errors;

	private _token: string;
	getToken() { return this._token; }
	setToken(token: string, useSession?: boolean) {

		const key = this._getTokenKey();

		if (this._token !== token) {
			this._token = token;
			if (token) {
				(useSession ? sessionStorage : localStorage).setItem(key, token)
				this.token.next(token);
			} else {
				(useSession ? sessionStorage : localStorage).removeItem(key);
				this.token.next(null);
			}
		}

		// inform the mobile wrapper that the token changed, JUST in case
		this.mobile.updateToken(token);
	}
	
	environmentSlices: {[sliceName: string]: number} = {};
	settings = new BehaviorSubject<SettingRow[]>(null);

	public spoke?: string;
	public url?: string;
	public uploadUrl?: string;
	public settingsUrl?: string;
	public allowGuest = true;
	public notify = new Notify();
	
	private _login: Login;;
	private _fileUrl: string;
	socketUrl: string;

	constructor(
		config: ApiConfig,
		public mobile = Mobile.instance(), // simple DI... lol
		public barcode = Barcode.instance(),
		public qrcode = Qrcode.instance(),
	) {
		config = config || {};

		if (!Api.instance) {
			Api.instance = this;
		}

		this.spoke = config.spoke || location.hostname.split('.').shift();
		this.url = config.url || Api.defaultUrl;
		this.uploadUrl = config.uploadUrl || Api.defaultUploadUrl;
		this._fileUrl = config.fileUrl || Api.defaultFileUrl;
		this.settingsUrl = config.settingsUrl || Api.defaultSettingsUrl;
		this.socketUrl = config?.socketUrl || Api.defaultSocketUrl.replace("SPOKE", this.spoke);

		if (config.hasOwnProperty('allowGuest')) {
			this.allowGuest = !!config.allowGuest;
		}
		this._token = sessionStorage.getItem(this._getTokenKey()) || localStorage.getItem(this._getTokenKey());

		this.hitchWithAuthenticate = config.hitchWithAuthenticate || [];

		if (config.autoAuthenticate) {
			if (this.allowGuest || this._token) {
				this.authenticate()
					.subscribe();
			} else {
				this.showLogin()
					.subscribe();
			}
		}

		// preventSocketAutoConnect
		if (!config.preventSocketAutoConnect) {
			this.connectToSocketServer();
		}
	}

	connectToSocketServer(url = this.socketUrl) {
		if (!url) {
			console.warn('no socket url provided');
			return;
		}
		try {
			const socket = this.socket = new AuxiliumSocketClient(url);
			this.token
				.pipe(
					startWith(this._token)
				)
				.subscribe(token => {
					const spoke = this.spoke;
					if (token) {
						socket.resume(token, spoke);
					}
				});
			} catch (err) {
				console.warn('unable to create socket client instance.  have you installed the peer dependancies?', {err, this: this});
			}
	}

	getFileUrl(id: number, ignoreToken?: boolean): string {
		let url = `${this._fileUrl}?id=${id}`;
		if (!ignoreToken) {
			const token = this.getToken();
			if (token) {
				url += `&token=${token}`;
			}
		}
		return url;
	}

	getTimezone() {
		return localStorage.getItem(Api._timezoneKey) || Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

	setTimezone(tz: string) {
		const key = Api._timezoneKey;
		if (tz) {
			localStorage.setItem(key, tz)
		} else {
			localStorage.removeItem(key);
		}
	}

	getCurrentAuth() {
		return this._auth.getValue();
	}

	isValidUser(): Observable<boolean> {
		return this
			.request<AuthRow>({'$/auth/current': {}}, {labelAs: 'api.isValidUser'})
			.pipe(
				map(a => !a.guest),
			);
	}

	isSysAdmin(): Observable<boolean> {
		return this
			.report<UserRow>('sysadmins', {where: ['$eq', ['$field', 'auth_ref'], ['$viewer']]})
			.pipe(
				catchError(err => of({rows: []})),
				map(r => r.rows && r.rows.length ? true : false)
			)
	}

	isTableAdmin(): Observable<boolean> {
		return this
			.report<UserRow>('tableadmins', {where: ['$eq', ['$field', 'auth_ref'], ['$viewer']]})
			.pipe(
				catchError(err => of({rows: []})),
				map(r => r.rows && r.rows.length ? true : false)
			)
	}

	isUserAdmin(): Observable<boolean> {
		return this
			.report<UserRow>('useradmins', {where: ['$eq', ['$field', 'auth_ref'], ['$viewer']]})
			.pipe(
				catchError(err => of({rows: []})),
				map(r => r.rows && r.rows.length ? true : false)
			)
	}

	isInRole(role: number) {
		return this.isInRoles([role]);
	}
	
	isInRoles(role: number[]): Observable<boolean> {
		throw '@todo';
	}

	showLogin(auth?: Auth, assertionFn?: (auth: Auth) => boolean, assertionFailMessage?: string, assertionContext?: any) {

		const login = this._login || (this._login = new Login(this, auth)),
			settings = this.settings.getValue();

		if (!settings?.length) {
			return this
				.getSafeSettings()
				.pipe(
					switchMap(() => login.show(this.allowGuest, null, assertionFn, assertionFailMessage || 'This account lacks the nescessary permissions to view this content.', assertionContext)),
				);
		} else {
			return login.show(this.allowGuest, null, assertionFn, assertionFailMessage || 'This account lacks the nescessary permissions to view this content.', assertionContext);
		}
	}

	authenticate() {

		const adminCheckParams = {fields: {_validKey: ['$const', 1]}, where: ['$eq', ['$field', 'auth_ref'], ['$viewer']]},
			toHitch = this.hitchWithAuthenticate;
		let hitched: any[];
		
		if (toHitch.length) {
			hitched = [];
			toHitch.forEach(h => hitched.push(h.id, h.request));
		}

		return this.request<{
			auth: AuthRow,
			env: {[sliceName: string]: number},
			settings: SettingRow[],
			client: ClientRow,
			admin: AdminObject,
			hitched?: any,
			assumed?: boolean, // will be mixed during the tap
		}>({
			auth: {'$/auth/current': {}},
			env: {'$/env/all': {}},
			client: {'$/env/client': {}},
			settings: {'$/env/settings/report': {}, $pop: 'rows'},
			admin: {'$/tools/do': [
				ADMIN_KEYS.SYSTEM, {'$/env/sysadmins/report': adminCheckParams},
				ADMIN_KEYS.TABLE, {'$/env/tableadmins/report': adminCheckParams},
				ADMIN_KEYS.USER, {'$/env/useradmins/report': adminCheckParams},
				'respond', {
					system: {$_: `${ADMIN_KEYS.SYSTEM}:rows:0:_validKey`, $_else: 0},
					table: {$_: `${ADMIN_KEYS.TABLE}:rows:0:_validKey`, $_else: 0},
					user: {$_: `${ADMIN_KEYS.USER}:rows:0:_validKey`, $_else: 0},
				},
			]},
			hitched: hitched ? {'$/tools/do': hitched} : null,
		}, {labelAs: 'api.authenticate'})
		.pipe(
			tap(r => {
				if (r && r.auth && r.auth.token && r.auth.token !== this.getToken()) {
					this.setToken(r.auth.token);
				}
				if (r && r.settings) {
					this.settings.next(r.settings);
				}
				this.environmentSlices = r.env;

				r.assumed = sessionStorage.getItem(this._getTokenKey()) === r.auth.token;
			}),
			map(r => new Auth(r.auth, r.settings, r.client, r.admin, r.hitched, r.assumed)),
			switchMap(a => this.allowGuest || (a && !a.guest) ? of(a) : this.showLogin(a)),
			tap(a => this._auth.next(a)),
		)
	}

	/**
	 * sends instructions on how to reset the users password to the email on file
	 * 
	 * @param login email addres (or login id) that is being used to send the email too
	 */
	sendRescueEmail(login: string) {
		// this call has a really goofy return signature, be carefull
		return this
			.request<{notification: number}[]>({'$/auth/rescue_email': {user: {$or: {login, email: login}}}}, {labelAs: 'api.sendRescueEmail', ignoreGuestCheck: true})
			.pipe(
				catchError(err => of([{notification: 0}])),
				map(r => r && r[0] && r[0].notification)
			)
	}

	sendRescueSms(user: string, voiceCall = false) {
		return this
			.request<ApiRescueSms>({'$/auth/mobile/generate': {user, method: voiceCall ? 'voice' : 'sms'}}, {labelAs: 'api.sendRescueSms', ignoreGuestCheck: true})
			.pipe(
				catchError(err => of<ApiRescueSms>({status: 0})),
				map(r => !!(r?.status))
			)
	}

	//  $user, $pin, $password
	updatePasswordFromRecovery(login: string, newPassword: string, verifyCode?: string) {
		return this
			.request<ApiChangePassFromRecovery | string>({'$/auth/mobile/rescue': {
				user: login,
				password: newPassword,
				pin: verifyCode
			}}, {labelAs: 'api.updatePasswordFromRecovery', ignoreGuestCheck: true})
			.pipe(
				catchError(err => of<ApiChangePassFromRecovery>(err)),
				switchMap(r => {
					if (typeof r !== 'string' && ('token' in r)) {
						this.setToken(r.token);
						return this.authenticate();
					} else {
						switch (this._makeErrorSafe(r)) {
							case 'bad_or_expired_pin':
								this.notify.warn('Invalid or expired verification code.');
								break;
							default:
								this.notify.warn(r || 'An unknown error occured');
						}
					}
					return of<false>(false);
				})
			)
	}

	
	login(login: string, password: string, secret?: string): Observable<false | Auth | 'SMS' | 'GOOGLE_AUTHENTICATOR'> {

		const frm = new FormData(),
			url = `${this.url}/${Api.loginEndpoint}`;
			
		let cfg: RequestInit = {
			method: 'POST',
			body: frm,
			credentials: 'omit',
		};

		frm.append('login', login);
		frm.append('password', password);
		frm.append('realm', this.spoke);
		

		if (secret) {
			frm.append('secret', secret);
		}

		return defer(
				() => from(fetch(url, cfg))
			)
			.pipe(
				switchMap(r => from(r.json())),
				map((resp: ValidLogin | LoginError) => {
					if ('error' in resp) {
						switch (resp.error) {
							case 'SMS':
							case 'GOOGLE_AUTHENTICATOR':
								return resp.error;
						}
						return false as false;
					} else {
						this.setToken(resp.token);
						return true;
					}
				}),
				switchMap(a => iif(
					() => a === true,
					this.authenticate(),
					of(a as false | 'SMS' | 'GOOGLE_AUTHENTICATOR')
				))
			);
	}

	logout(all?: boolean) {
		const key = this._getTokenKey(),
			session = sessionStorage.getItem(key),
			local = localStorage.getItem(key);
		let sessionObs: Observable<Response>;
		if (all && session && local) {
			sessionObs = defer(() => from(fetch(`${this.url}/${Api.logoutEndpoint}`, {
				method: 'GET',
				credentials: 'omit',
				headers: this._buildHeaders({}),
			})));
			// now, ensure its wiped
			sessionStorage.removeItem(key);
			// and hack the token back to the local version
			this._token = local;
		} else {
			sessionObs = of(null);
		}
		return zip(
			defer(() => from(fetch(`${this.url}/${Api.logoutEndpoint}`, {
				method: 'GET',
				credentials: 'omit',
				headers: this._buildHeaders({}),
			}))),
			sessionObs
		)
		.pipe(
			catchError(() => of(null)),
			tap(r => this._clearToken()),
			switchMap(() => this.authenticate())
		);
	}

	request<T>(req: any, opt: ApiRequestOptions = {}, isRetry?: boolean): Observable<T> {

		const args = {body: this._encode(req)};
		let url = opt.url || this.url;

		if (opt && opt.labelAs) {
			url = `${url}?${opt.labelAs}`;
		}

		return this
			._request(url, args, opt?.ignoreGuestCheck)
			.pipe(
				switchMap(r => from(r.json())),
				switchMap((r: ApiRequestResponse) => {
					if (r && r.error) {
						const err = this._makeErrorSafe(r.error);
						if (err === 'invalid_grant') {
							this._clearToken();
							console.warn('invalid token format, clearing');
							if (!isRetry) {
								console.warn('retrying request...');
								return this.request(req, opt, true);
							}
						} else if (/expired/i.test(err) && err !== 'bad_or_expired_pin') {
							return this._sessionExpired(opt.token || this.getToken(), req, opt);
						} else {
							console.warn('unhandled api.request', {req, error: r.error, opt, response: r});
						}
						throw `${r.error}`;
					}
					return of(r);
				}),
				map(r => this._stripDebug(r)),
				map(r => this._recursiveDecodeResponse(r))
			);
	}

	private _makeErrorSafe(err: string) {
		return ('' + err).toLowerCase().trim().replace(/\s/g, '_');
	}

	getSafeSettings(): Observable<SettingRow[]> {
		return defer(() => from(fetch(this.settingsUrl, {
			cache: 'no-cache',
			credentials: 'omit',
			method: 'get',
			headers: this._buildHeaders({}),
		})))
		.pipe(
			timeout(1500), // we only give it a 1s delay, because its not that important
			switchMap(r => from(r.json())),
			tap(rows => {
				this.settings.next(rows)
			}), // share these
			catchError(() => of([])),
		);
	}

	upload(files: FileList | File[], associate?: AssociateUpload, updateSliceRow = true, updatePkCol = 'id') {

		const slice = associate?.slice,
			field = associate?.field,
			row = associate?.record;

		return this._uploadRequest(files)
			.pipe(
				switchMap(resp => {
					if (associate) {
						const ids = Array.from(new Set([ ...Object.values(resp).map(r => r.id), ...(associate.ids || [])]));
						return this
							.request<AssociateResponse>({'$/tools/do': [
								'update', updateSliceRow ? {'!/slice/xupdate': {slice, rows: [{[updatePkCol]: row, [field]: JSON.stringify(ids)}]}} : {ignore: 'me'},
								'file', this.makeAssociateRequest(ids, row, field, slice, true),
							]})
							.pipe(
								map(associated => {
									associated.files = resp;
									return associated;
								})
							);
					} else {
						return of(resp);
					}
				})
			)
	}

	associateUpload(ids: number[], rowId: number, field: string, slice: number) {
		return this
			.request<AssociateResponse>(this.makeAssociateRequest(ids, rowId, field, slice, false));
	}

	makeAssociateRequest(ids: number[], rowId: number | object, field: string, slice: number, partOfChain?: boolean) {
		return {
			[`${partOfChain ? '!' : '$'}/tools/file/update`]: {row: rowId, field, slice, ids},
		};
	}

	/**
	 * 
	 * @param files array of files to be sent to the server
	 * @param row the row that is going to be inserted into the db
	 * @param slice the target slice for the row
	 * @param field the field in the row that the FILES are stored in
	 * @param recordPkCol the primary column in the target slice (default 'id')
	 */
	uploadAndInsertRow(files: FileList | File[], row: object, slice: number, field: string, recordPkCol = 'id') {
		return this._uploadRequest(files)
			.pipe(
				switchMap(resp => {
					const fileIds = resp.map(r => r.id)
					Object.assign(row, {[field]: JSON.stringify(fileIds)});
					return this.request<ApiXInsertResponse>({'$/tools/do': [
						'insert', {'!/slice/xinsert': {
							slice,
							rows: [row]
						}},
						'updateMap', this.makeAssociateRequest(fileIds, {$_: `insert:keys:0`}, field, slice, true),
						'responds', {$_: 'insert'},
					]});
				})
			);
	}

	report<T>(slice: number | string, obj: any = {}, fileFields?: (keyof T)[]) {
		return this.request<{
			report: ApiReportResponse<T>,
			files: ApiFilenameResponse,
		}>({'$/tools/do': [
			'report', this.formatRecordRequest(slice, obj, RecordRequest.report, true),
			'files', fileFields?.length ? {'!/tools/file/byFieldsInRows': {fields: fileFields, rows: {$_: 'report:rows', $_else: []}}} : [],
			'all', {$_: '*'},
		]}, {labelAs: `api.report.${slice}`})
		.pipe(
			map(r => ({
				rows: r.report.rows,
				count: r.report.hasOwnProperty('count') ? r.report.count : null,
				files: r.files,
			} as ApiReportResponse<T>))
		)
	}

	/**
	 * runs a slice/report, and automatically bundles in the permissions for the rows
	 * 
	 * @param slice the number (or, if saved in the environment, the name) of the slice to query
	 * @param obj the params to be sent as the slice/report args
	 * @param id the primary key column of the underlying slice (defaults to 'id')
	 * @param fileFields if any fields in the response are files, and you want to include their meta data in the response
	 */
	reportWithPerms<T>(slice: number | string, obj: any = {}, id: string = 'id', fileFields?: (keyof T)[]) {
		
		obj = obj || {};
		obj.return = obj.return || {};
		obj.return.rows = true;
		obj.return.count = true;

		const req = {'$/tools/do': [
			'report', this.formatRecordRequest(slice, obj, RecordRequest.report, true),
			'pkeys', {'!/tools/column': {col: id, rows: {$_: 'report:rows', $_else: []}}},
			'perms', this.formatRecordRequest(slice, {mask: 'ud', pkeys: {$_: 'pkeys', $_else: []}}, RecordRequest.perms, true),
			'files', fileFields?.length ? {'!/tools/file/byFieldsInRows': {fields: fileFields, rows: {$_: 'report:rows'}}} : [],
			'all', {
				report: {$_: 'report'},
				perms: {$_: 'perms'},
				files: {$_: 'files'},
			},
			// 'all', {$_: '*'},
		]};
		return this.request<{
				report: ApiReportResponse<T>,
				perms: ApiXPermsResponse,
				files: ApiFilenameResponse,
			}>(req, {
				labelAs: `api.reportWithPerms.${slice}`,
			})
			.pipe(
				map(r => ({
					rows: this.mixPermsIntoRows(r.report.rows, r.perms, id),
					count: r.report.hasOwnProperty('count') ? r.report.count : null,
					files: r.files,
				} as ApiReportResponse<T & PermBits>))
			);
	}

	deleteRow<T>(slice: number | string, pkey: number): Observable<ApiTransactionResponse> {
		return this.deleteRows(slice, [pkey]);
	}
	deleteRows(slice: number | string, pkeys: number[]): Observable<ApiTransactionResponse> {
		const req = this.formatRecordRequest(slice, {pkeys}, RecordRequest.delete);
		return this
			.request<ApiXDeleteResponse>(req, {labelAs: `api.deleteRows.${slice}`})
			.pipe(
				catchError(err => {
					console.warn('an error occured\n', {request: req, error: err});
					return of<ApiXDeleteResponse>({tx: null, keys: []});
				}),
				map(resp => {
					const keys = new Set(resp.keys || []),
						sentKeys = Array.from(new Set(pkeys)),
						success = sentKeys.every(id => keys.has(id));
					return {
						success,
						transaction: resp.tx || null,
						keys: resp.keys || [],
					}
				})
			)

	}

	insertRow<T>(slice: number | string, row: T): Observable<ApiTransactionResponse> {
		return this.insertRows(slice, [row]);
	}
	insertRows<T>(slice: number | string, rows: T[]): Observable<ApiTransactionResponse> {
		const req = this.formatRecordRequest(slice, {rows}, RecordRequest.insert);
		return this
			.request<ApiXInsertResponse>(req, {labelAs: `api.insertRows.${slice}`})
			.pipe(
				catchError(err => {
					console.warn('an error occured\n', {request: req, error: err});
					return of<ApiXInsertResponse>({keys: [], tx: null});
				}),
				map(r => {
					const success = (r.keys || []).length === rows.length;
					return {
						success,
						transaction: r.tx || null,
						keys: r.keys || [],
					}
				})
			)
	}

	updateRow<T, K extends keyof T>(slice: number | string, row: T, idField?: K): Observable<ApiTransactionResponse> {
		return this.updateRows(slice, [row], idField);
	}
	updateRows<T, K extends keyof T>(slice: number | string, rows: T[], idField: K  = 'id' as K): Observable<ApiTransactionResponse> {
		const req = this.formatRecordRequest(slice, {rows}, RecordRequest.update);
		return this
			.request<ApiXUpdateResponse>(req, {labelAs: `api.updateRows.${slice}`})
			.pipe(
				catchError(err => {
					console.warn('an error occured\n', {request: req, error: err});
					return of<ApiXUpdateResponse>({keys: [], tx: null});
				}),
				map(resp => {
					const keys = new Set(resp.keys || []),
						sentKeys = Array.from(new Set(rows.map(r => +r[idField]))),
						success = sentKeys.every(key => keys.has(key));
					return {
						success,
						transaction: resp.tx,
						keys: resp.keys || [],
					}
				})
			);
	}

	formatRecordRequest(slice: number | string, data: any, op: RecordRequest, partOfChain?: boolean) {
		const isNum = typeof slice === 'number',
			func = partOfChain ? '!' : '$',
			req: any = {};
		let path: string;
		data = data || {};
		if (isNum) {
			path = `${func}/slice/${op}`;
			req[path] = Object.assign({slice}, data);
		} else {
			path = `${func}/env/${slice}/${op}`;
			req[path] = data;
		}
		return req;
	}

	mixPermsIntoRows<T>(rows: T[], perms: ApiXPermsResponse, id = 'id') {
		const d = new Set(perms.delete),
			u = perms.update || {};
		return rows.map((r: any) => {
			const upd = u[r[id]] || {},
				bits: PermBits = {
					_deletable: d.has(r[id]),
					_updatable: new Set(Object
						.keys(upd)
						.map(k => upd[k] && upd[k].length && upd[k].some && upd[k].some(x => !!x) ? k : null)
						.filter(k => !!k)
					)
				};			
			return Object.assign(r, bits) as T & PermBits
		});
	}

	/**
	 * this function will let you become another user for
	 * this session (unique to the window)
	 * @param id the auth.id you want to assume
	 */
	assume(id: number) {
		return this
			.request<AuthRow>({'$/report/users/become': {id}})
			.pipe(
				switchMap(auth => {
					if (auth.token !== this._token) {
						this.setToken(auth.token, true);
						return this.authenticate();
					}
					throw 'rejected';
				}),
				catchError(err => {
					throw 'rejected';
				})
			);
	}

	// mixFilesIntoRows<T>(rows: T[], files: ApiFilenameResponse, fileFields: (keyof T)[]) {
	// 	if (fileFields?.length) {
	// 		return rows.map(row => {
	// 			fileFields.forEach(field => {
	// 				if (row[field] && typeof row[field] === 'string' && /^\[.*\]$/.test('' + row[field])) {
	// 					try {
	// 						const ids = JSON.parse('' + row[field]) as number[];
	// 						(row as any)[`_files_${field}`] = ids.map(id => files[id]).filter(f => !!f);
	// 					} catch (err) {
	// 						console.warn('malformed json structure', row[field]);
	// 					}
	// 				}
	// 			});
	// 			return row;
	// 		});
	// 	}
	// 	return rows;
	// }

	private _clearToken() {
		// check to see if we have a sessionToken..
		const key = this._getTokenKey(),
			session = sessionStorage.getItem(key);
		if (session) {
			sessionStorage.removeItem(key);
			this.setToken(localStorage.getItem(key) || null);
		} else {
			this.setToken(null);
		}
	}


	private _unauthorizedLoginAs: {login: string, password: string, secret?: string, previousAllowGuest: boolean};
	
	/**
	 * @description defines a fallback user/pass to login as when auth fails
	 * @warn 2FA will not work!!
	 * @warn GUEST will be ignored if enabled
	 * @param login email/login or whatever that is used to login
	 * @param password password for auth account
	 */
	unauthorizedLoginAs(login: string, password: string, secret?: string) {
		const guest = this.allowGuest;
		this._unauthorizedLoginAs = {login, password, secret, previousAllowGuest: guest};
		if (guest) {
			this.allowGuest = false;
			console.warn(`forcing a user when guest access is enabled will prevent the guest account from being used until it is cleared via 'clearForceUser()'`);
		}
	}

	clearUnauthorizedLoginAs() {
		const unauth = this._unauthorizedLoginAs;
		if (unauth) {
			this.allowGuest = !!unauth.previousAllowGuest;
		}
		this._unauthorizedLoginAs = null;
	}

	private _request(url: string, cfg: RequestInit, ignoreGuestCheck = false): Observable<Response> {


		if (!('cache' in cfg)) cfg.cache = 'no-cache';
		if (!('credentials' in cfg)) cfg.credentials = 'omit'; 
		if (!('method' in cfg)) cfg.method = 'post';
		cfg.headers = this._buildHeaders(cfg);

		const token = this.getToken();

		if (!token && !ignoreGuestCheck) {
			const unauth = this._unauthorizedLoginAs;
			if (unauth) {
				const {login, password, secret} = unauth;
				this.login(login, password, secret)
					.subscribe(r => {
						if (r) {
							cfg.headers = this._buildHeaders(cfg);
							return defer(() => from(fetch(url, cfg)));
						} else {
							throw 'forceUser failed';
						}
					})
			} else if (this.allowGuest) {
				return this._loginAsGuest()
					.pipe(
						switchMap(() => {
							// rebuild the headers now, the token should be stored!
							cfg.headers = this._buildHeaders(cfg);
							return defer(() => from(fetch(url, cfg)))
						}),
					)
			} else {
				this.showLogin()
					.subscribe();
				throw 'guest_restricted';
			}
		} else {
			return defer(() => from(fetch(url, cfg)));
		}

	}

	loginAsGuest() {
		return this._loginAsGuest();
	}

	private _loginAsGuest() {
		return defer(() => from(fetch(`${this.url}/${Api.guestEndpoint}`, {
			method: 'GET',
			credentials: 'omit',
			headers: this._buildHeaders({}, true),
		})))
		.pipe(
			switchMap(r => from(r.json())),
			map(r => {
				const token: string = r.token || null
				this.setToken(token);
				return token;
			}),
		)
	}

	private _buildHeaders(cfg: RequestInit, ignoreToken = false) {
		const headers = new Headers('headers' in cfg ? cfg.headers : Api.defaultHeaders),
			token = this.getToken(),
			socket = this.socket?.id;
		if (token && !ignoreToken) {
			headers.set(Api.tokenHeader, Api.formatTokenForHeader(token));
		} else if (this.spoke) {
			headers.set(Api.spokeHeader, this.spoke);
		}
		if (socket) {
			headers.set(Api.socketHeader, socket);
		}
		headers.set('timezone', this.getTimezone());
		return headers;
	}

	// private _isAuthRow(resp: any): resp is AuthRow {
	// 	return !!(resp as AuthRow).id;
	// }

	// should we store a cached request signature
	private _sessionExpired(token: string, username: string, req: any, opt: ApiRequestOptions = {}) {
		if (username) {
			const login = this._login || (this._login = new Login(this, null));
			return login
				.show(this.allowGuest, {token, username})
				.pipe(
					filter(a => !!a),
					take(1),
					switchMap(auth => this.request(req, opt))
				);
		} else {
			console.warn('no username found, unable to resume');
		}
		throw 'session_expired';
	}

	// this takes a file array, and makes each call its own, and
	// resolves as such
	private _uploadRequest(files: FileList | File[]) {
		const url = this.uploadUrl;
		return forkJoin(
			(files instanceof FileList ? Array.from(files) : files)
				.map(file => {
					const formData = new FormData();
					formData.append(fileUploadKey, file, file.name);
					return this
						._request(url, {body: formData, headers: {}}) // intentionally send empty headers
						.pipe(
							switchMap(r => from(r.json())),
							map((resp: UploadResponse) => resp.files[fileUploadKey]),
							map(f => {
								if ('error' in f) {
									throw f.error;
								}
								f.url = this.getFileUrl(f.id);
								return f;
							})
						);
				})
			);
	}

	private _encode(obj: any): string {
		Date.prototype.toJSON = _customDateToJson;
		const ret = JSON.stringify(obj);
		Date.prototype.toJSON = _defaultDateToJson;
		return ret;
	}

	private _recursiveDecodeResponse(resp: any) {
		const t = Object.prototype.toString.call(resp);
		switch (t) {
			case '[object Object]':
				Object.keys(resp)
					.forEach(key => {
						if (Api.conversions.has(key)) {
							resp = Api.conversions.get(key)(resp);
						} else {
							resp[key] = this._recursiveDecodeResponse(resp[key]);
						}
					});
				break;
			case '[object Array]':
				resp = resp.map((v: any) => this._recursiveDecodeResponse(v));
				break;
		}
		return resp;
	}

	private _stripDebug(resp: any) {
		if (resp && resp['debug-log']) {
			delete resp['debug-log'];
		}
		return resp;
	}
	
	private _getTokenKey() {
		const url = this.url;
		return `authorization-${url}`;
	}

}