export enum ADMIN_KEYS {
	SYSTEM = 'system',
	USER = 'user',
	TABLE = 'table',
}

export interface AdminObject {
	[ADMIN_KEYS.SYSTEM]?: 0 | 1;
	[ADMIN_KEYS.TABLE]?: 0 | 1;
	[ADMIN_KEYS.USER]?: 0 | 1;
}