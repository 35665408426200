import { Subject } from "rxjs";

type IOS = 'ios';

interface AndroidObject {
	createToast: (message: string) => void;
	isTracking: () => boolean;
	openWalkieTalkie: () => void;
	openFrontCamera: (xyMaxPixels?: number) => void;
	setVolume: (volume: number) => void;
	startTracking: (minutes: number) => void;
	stopTracking: () => void;
	soundAlarm: () => void;
	updateToken: (token: string) => void;
}

enum IOS_TYPE {
	IS_TRACKING = 'isTracking',
	SOUND_ALARM = 'soundAlarm',
	SET_VOLUME = 'setVolume',
	START_TRACKING = 'startTracking',
	START_WALKIE_TALKIE = 'startLynk',
	STOP_TRACKING = 'stopTracking',
	UPDATE_TOKEN = 'updateToken',
}

declare global {
	interface Window {
		AMS: IOS | AndroidObject;
		my: {
			namespace: {
				publicFunc?: (base64: string) => void;
			}
		}
	}
}

export type MobileEventFn = (... args: any[]) => void;

export class Mobile {

	static instance(win = window) {
		let inst = Mobile._instance;
		if (!inst) {
			inst = Mobile._instance = new Mobile();
			if (!win.my) win.my = {namespace: {}};
			if (!win.my.namespace) win.my.namespace = {};
			win.my.namespace.publicFunc = inst._captureFrame.bind(inst);
		}
		return inst;
	}
	private static _autoId = 0;
	private static _instance: Mobile;

	private _captureRequests: Set<{sub: Subject<File>, filename: string}> = new Set();

	protected eventListeners: {[key: string]: MobileEventFn[]} = {};

	/**
	 * @description add a callback to an event that the AMS instance would fire
	 * @param eventName name of the event to listen for
	 * @param callbackFn your callback function
	 * @todo this should really whitelist the available event names, and provide
	 * better function signatures
	 * also, i'm not certain what is calling these, and if it relies on the 'eventListeners'
	 * key being present in some global namespace
	 * 
	 * also, why no removeeventListener?  how do we stop?
	 */
	addEventListener<T>(eventName: string, callbackFn: (... args: any[]) => any) {
		const el = this.eventListeners,
			arr = el[eventName] || (el[eventName] = []);
		arr.push(callbackFn);		
	}

	/**
	 * @description launches the front facing camera and takes a picture, closing
	 * it immediately (ANDROID only)
	 * @param filename string name for the file (with .ext! or the upload may fail)
	 * @param xyMaxPixels max width or height of the image, in pixels
	 * @notes Android only; call canCaptureFrontPhoto if you want to check
	 * @return Subject<File | null> as subject that resolves when a file is returned,
	 * or NULL if not available/cancelled
	 */
	
	captureFrontPhoto(filename?: string, xyMaxPixels?: number) {
		const sub = new Subject<File>(),
			req = {filename, sub},
			subs = this._captureRequests;
		if (!filename) {
			req.filename = `capture_${++Mobile._autoId}.jpg`;
		}
		subs.add(req);
		setTimeout(() => {
			const android = this._android();
			if (android) {
				this._captureFrontPhoto(xyMaxPixels);
			} else {
				this._invalidPlatformMessage('catpureFrontPhoto');
				sub.next(null);
				sub.complete();
				subs.delete(req);
			}
		}, 1);
		return sub;
	}


	/**
	 * @description popup a 'toast' message on the device
	 * @param message string message to display
	 */
	createToast(message: string) {
		const android = this._android();
		message = String(message || '');
		if (android) {
			if (message.length) {
				android.createToast(message);
			} else {
				console.warn('createToast invalid message length');
			}
		} else {
			this._invalidPlatformMessage('createToast');
		}
	}

	/**
	 * @description fires events
	 * @param evtName name of the event to fire... 
	 * @todo Should only be used by AMS app. This fires events reflecting the state of the AMS app
	 * 
	 * whatever that means..
	 */
	fireEvent(evtName: string) {
		(this.eventListeners[evtName] || [])
			.forEach(evt => evt());
	}

	/**
	 * @description checks if we are currently tracking
	 * @param status its supposed to be boolean, but i'll have to ask Andre, as theres some
	 * giggiggitty hapenning, so i just cloned it.. 
	 */
	isTracking<T>(status?: T): boolean | T | 'start' {
		const android = this._android(),
			ios = this._ios();
		if (android) {
			return android.isTracking();
		} else if (ios) {
			this._iosRoute(IOS_TYPE.IS_TRACKING);
			// i'm not going to pretend to understand what the fuck this is doing..
			if (status === null) {
				return 'start';
			} else {
				return status;
			}
		} else {
			this._notAvailableMessage();
			return false;
		}
	}

	/**
	 * @description opens the walkie talkie
	 */
	openWalkieTalkie(): void {
		const android = this._android(),
			ios = this._ios();
		if (android) {
			android.openWalkieTalkie();
		} else if (ios) {
			this._iosRoute(IOS_TYPE.START_WALKIE_TALKIE);
		} else {
			this._notAvailableMessage();
		}
	}

	/**
	 * @description set the device volume level
	 * @param volume from 0 to 1
	 */
	setVolume(volume: number) {
		if ((!volume && volume !== 0) || volume < 0 || volume > 1) {
			console.warn('invalid volume level, must be between 0 and 1');
			return;
		}
		const android = this._android(),
			ios = this._ios();
		if (android) {
			android.setVolume(volume);
		} else if (ios) {
			this._iosRoute(IOS_TYPE.SET_VOLUME, {volume});
		} else {
			this._notAvailableMessage();
		}
	}

	/**
	 * @description plays an audible alarm
	 */
	soundAlarm(): void {
		const android = this._android(),
			ios = this._ios();
		if (android) {
			android.soundAlarm();
		} else if (ios) {
			this._iosRoute(IOS_TYPE.SOUND_ALARM);
		} else {
			this._notAvailableMessage();
		}
	}

	/**
	 * @description Begin tracking GPS location of device for {minutes} in Datalynk
	 * @param minutes number of minutes to track phone (-1 for user default, RECOMMENDED)
	 * @returns false if not available, or a function that can be called to stopTracking
	 */
	startTracking(minutes?: number) {
		minutes = minutes || -1;
		const android = this._android(),
			ios = this._ios(),
			retFn: () => void = this.stopTracking.bind(this);
		if (android) {
			android.startTracking(minutes);
		} else if (ios) {
			this._iosRoute(IOS_TYPE.START_TRACKING, {time: minutes});
		} else {
			this._notAvailableMessage();
		}
		return retFn;
	}

	/**
	 * @description stops tracking
	 */
	stopTracking(): void {
		const android = this._android(),
			ios = this._ios();
		if (android) {
			android.stopTracking();
		} else if (ios) {
			this._iosRoute(IOS_TYPE.STOP_TRACKING);
		} else {
			this._notAvailableMessage();
		}
	}

	updateToken(token: string | null) {
		token = token || ''; // force to empty string on undef/null/empty/0
		const android = this._android(),
			ios = this._ios();
		if (android) {
			android.updateToken(token);
		} else if (ios) {
			this._iosRoute(IOS_TYPE.UPDATE_TOKEN, {token});
		} else {
			this._notAvailableMessage();
		}
	}

	
	// returns the instance IF it is android
	private _android(win = window): AndroidObject {
		return win?.AMS ? win?.AMS === 'ios' ? null : win.AMS : null;
	}

	/**
	 *
	 * @description this just calls our subscriber and cleans up
	 * after itself when it hears requests from the mobile app
	 *
	 * @param base64 data sent from the mobile app
	 */
	private _captureFrame(base64: string) {
		const subs = this._captureRequests;
		if (subs.size) {
			if (base64) {
				const bin = atob(base64),
					len = bin.length,
					bytes = new Uint8Array(len);
				for (let i = 0; i < len; i++) {
					bytes[i] = bin.charCodeAt(i);
				}
				const blob = new Blob([bytes.buffer], {type: 'image/jpeg'});
				subs.forEach(params => {
					const file = new File([blob], params.filename),
						sub = params.sub;
					params.sub.next(file);
					sub.next(file);
					sub.complete();
					subs.delete(params);
				});
			} else {
				subs.forEach((p) => {
					p.sub.next(null);
					p.sub.complete();
					subs.delete(p);
				});
			}
		}
	}

	private _captureFrontPhoto(xyMaxPixels?: number) {
		const android = this._android();
		if (android) {
			android.openFrontCamera(xyMaxPixels);
		} else {
			this._invalidPlatformMessage('captureFrontPhoto');
		}
	}

	// returns the string IF it is ios
	private _ios(win = window): IOS {
		return win?.AMS ? win?.AMS === 'ios' ? win.AMS : null : null;
	}

	// handles the 'location.href' mapping to the app, basic check to ensure
	// that we've registered the key (for type safety);
	private _iosRoute(type: IOS_TYPE, obj: {[key: string]: any} = {}) {
		const payload = JSON.stringify(Object.assign(obj, {type}));
		location.href = `auxmobile://${payload}`;
	}


	// just a wrapper to make loggin a bit easier/cleaner
	private _invalidPlatformMessage(funcName: string, platformTarget = 'android') {
		console.warn(`"${funcName}" requires the "${platformTarget}" platform to be loaded`);
	}

	private _notAvailableMessage() {
		console.warn(`AMS not found`);
	}

}
