export interface TemplatedParams {
	style?: string;
	tag: string;
	template: string;
}
export abstract class Templated {

	protected domNode: HTMLElement;
	protected styleNode: HTMLStyleElement;

	private _shadow: ShadowRoot
	
	protected getElementById(id: string) {
		return this._shadow.getElementById(id);
	}

	protected querySelector(qry: string) {
		return this._shadow.querySelector(qry) as HTMLElement;
	}
	protected querySelectorAll(qry: string) {
		return Array.from(this._shadow.querySelectorAll(qry)) as HTMLElement[];
	}

	constructor(params: TemplatedParams) {
		this._build(params);
	}

	private _build(p: TemplatedParams) {

		const tpl = p.template,
			root = document.createElement(p.tag),
			shadow = this._shadow = root.attachShadow({mode: 'closed'}),
			tempNode = document.createElement('div'),
			style = document.createElement('style'),
			injectedStyle = this.styleNode = document.createElement('style');

		style.textContent = p.style || '';
		shadow.appendChild(style);
		shadow.appendChild(injectedStyle);

		tempNode.innerHTML = tpl;
		Array.from(tempNode.childNodes)
			.forEach((n: HTMLElement) => {
				shadow.appendChild(n);
			})

		this.domNode = root;
	}

}