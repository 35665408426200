import { Templated } from "./Templated";
import { host } from "./host";

export class Notify extends Templated {

	private _messageNode: HTMLElement;
	private _containerNode: HTMLElement;
	private _durationTimeout: number;

	constructor() {

		super({
			tag: 'api-notify',
			template: `<div class="notify"><div class="message"></div></div>`,
			style: `
:host {
	display: inline-block;
	position: fixed;
	max-width: 300px;
	z-index: 99999;
	${host}
}
.notify {
	padding: 12px;
	border-radius: 4px;
	font-size: 14px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	transition: opacity 150ms linear;
}
.notify.hide {
	opacity: 0;
}
.notify.warn {
	background-color: var(--warn);
	color: var(--warn-fore);
}
.notify.accent {
	background-color: var(--accent);
	color: var(--accent-fore);
}
`,
		});
		this._containerNode = this.querySelector('.notify');
		this._messageNode = this.querySelector('.message');
	}

	warn(message: string, durationMs?: number) {
		this._messageNode.innerHTML = message;
		this._setColor('warn');
		this._show(durationMs);
	}

	inform(message: string, durationMs?: number) {
		this._messageNode.innerHTML = message;
		this._setColor('accent');
		this._show(durationMs);
	}

	private _setColor(color: 'warn' | 'accent' | 'primary' = 'primary') {
		this._containerNode.className = `notify ${color}`;
	}

	private _show(durationMs: number = 4000) {

		if (this._durationTimeout) clearTimeout(this._durationTimeout);
		
		setTimeout(() => {
			const b = document.body,
				n = this.domNode,
				cont = this._containerNode;

			n.style.removeProperty('top');
			n.style.removeProperty('left');

			cont.classList.add('hide');
			b.insertBefore(n, b.firstChild);
			
			const innerH = innerHeight,
				innerW = innerWidth,
				h = cont.scrollHeight,
				w = cont.scrollWidth;
			
			b.addEventListener('click', () => this.dismiss());

			n.style.top = (innerH / 2 - h / 2) + 'px';
			n.style.left = (innerW / 2 - w / 2) + 'px';

			cont.classList.remove('hide');

			if (durationMs) {
				this._durationTimeout = +setTimeout(() => this.dismiss(), durationMs);
			}
		});
	}

	dismiss() {
		const n = this.domNode,
			par = n.parentNode,
			t = this._durationTimeout;
		if (t) clearTimeout(t);
		if (par) par.removeChild(n);
	}

}