import { _AuxiliumSocketClientBase } from './socket.base';
import { BehaviorSubject, Subject } from 'rxjs';
import { SliceRecordChanges, SliceCommentChanges, SliceChanges, RequestParams } from './socket.defs';

export class AuxiliumSocketClient extends _AuxiliumSocketClientBase {

	public offline = new BehaviorSubject<boolean>(false);
	public socketId = new BehaviorSubject<string>(null);

	private _firstRun = true;

	private _onRecordChangesObservers: {[spoke: string]: Map<number /* slice id */, Subject<SliceRecordChanges>>} = {}; // @@TODO, tighten the spec
	private _onCommentChangesObservers: {[spoke: string]: Map<number, Subject<SliceCommentChanges>>} = {};
	private _onSliceChangesObservers: {[spoke: string]: Map<number, Subject<SliceChanges>>} = {};
	private _tokens = new Map<string /** spoke */, string>();

	defaultSpokeName: string;

	constructor(
		public path?: string,
	) {
		super(path);
		this._init();
	}

	get id() {
		return this.socket.getValue();
	}

	private _init() {
		
		// when our connection status changes...
		this.socket
			.subscribe(id => {

				if (id) {
					console.log('socket: connected', id);
					this.offline.next(false);
					if (!this._firstRun) {
						this._resumeAll();
						// this.resume(this.token, this.defaultSpokeName);// this.spoke.next();
					}
					this._firstRun = false;
				} else {
					if (!this._firstRun) {
						console.log('socket: disconnected', id);
					}
					this.offline.next(true);
				}
				this.socketId.next(id || null);
			})

		this.on('spoke-authorized', (data) => {

			const spoke = data.auth.spoke || data.spoke;
			this._updateToken(spoke, data.auth.token);
			
			
			const onRecChange = this._onRecordChangesObservers[spoke],
				onComChange = this._onCommentChangesObservers[spoke],
				onSliChange = this._onSliceChangesObservers[spoke];

			if (onRecChange) {
				Array
					.from(onRecChange.keys())
					.forEach(sliceId => this.onRecordChanges(sliceId, spoke));
			}

			if (onComChange) {
				Array
					.from(onComChange.keys())
					.forEach(sliceId => this.onCommentChanges(sliceId, spoke));
			}

			if (onSliChange) {
				Array
					.from(onSliChange.keys())
					.forEach(sliceId => this.onSliceChanges(sliceId, spoke));
			}
		});

		this.on('unauthorized', (data: {spoke: string}) => {
			this._removeToken(data.spoke);
		});

		this.on('slice-changes', (data => this._onSliceChanges(data)));
		this.on('slice-records-changed', (data) => this._onRecordChanges(data));
		this.on('slice-comments-changed', (data) => this._onCommentChanges(data));

	}

	protected emit(evtName: string, data: any = {}, params: RequestParams = {}) { // hitches our spoke to outgoing requests...
		if (!params) params = {};
		if (!params.spoke) params.spoke = this.defaultSpokeName;
		if (!params.token) params.token = this.getToken(params.spoke);
		return super.emit(evtName, data, params);
	}

	private _resumeAll() {
		this._tokens
			.forEach((spoke, token) => {
				this.resume(token, spoke);
			});
	}

	private _updateToken(spoke: string, token: string) {
		this._tokens.set(spoke, token);
	}
	private _removeToken(spoke: string) {
		this._tokens.delete(spoke);
	}
	
	getToken(spoke: string) {
		return this._tokens.get(spoke) || null;
	}

	/**
	 * auth endpoints
	 */

	resume(token: string, spoke: string) {
		if (!token && !spoke) {
			console.warn('\n\nno token passed, unable to resume', {token, spoke}, '\n\n');
		} else {
			this.emit('join-spoke', null, {spoke, token});
		}

		// update it, if either is null, its ok
		this._updateToken(spoke, token);
	}

	login(login: string, password: string, params?: RequestParams) {
		this.emit('login', {login, password}, params);
	}

	logout(spoke?: string, params?: RequestParams) {
		params.spoke = spoke || params.spoke;
		this.emit('logout', null, params);
	}

	onSliceChanges(slice: number, spoke = this.defaultSpokeName) {
		const cache = this._onSliceChangesObservers;
		let slices = cache[spoke];
		if (!slices) {
			slices = new Map();
			cache[spoke] = slices;
		}
		let existing = slices.get(slice);
		if (!existing) {
			slices.set(slice, existing = new Subject());
			this.on('slice-changes', (evt: {slice: number, data: SliceChanges}) => {
				if (evt.slice === slice) {
					existing.next(evt.data);
				}
			})
			this.emit('slice-changes', {slice}, {spoke});
		}
		return existing;
	}


	onCommentChanges(slice: number, spoke = this.defaultSpokeName) {
		const cache = this._onCommentChangesObservers;
		let slices = cache[spoke];
		if (!slices) {
			slices = new Map();
			cache[spoke] = slices;		
		}
		let existing = slices.get(slice);
		if (!existing) {
			slices.set(slice, existing = new Subject());
			this.on('comment-changes', (evt: {slice: number, data: SliceCommentChanges}) => {
				if (evt.slice === slice) {
					existing.next(evt.data);
				}
			});
		}
		this.emit('slice-comment-changes', {slice}, {spoke});
		return existing;
	}


	/**
	 * slice/record based notifications
	 */
	onRecordChanges(slice: number, spoke = this.defaultSpokeName) {

		const cache = this._onRecordChangesObservers;
		let slices = cache[spoke];
		if (!slices) {
			slices = new Map();
			cache[spoke] = slices;
		}


		let existing = slices.get(slice);

		if (!existing) {
			slices.set(slice, existing = new Subject());
			
			// listen to changes here and 'add' them to the existing
			this.on('record-changes', (evt: {slice: number, data: SliceRecordChanges}) => {
				if (evt.slice === slice) {
					existing.next(evt.data);
				}
			});
		}

		this.emit('slice-record-changes', {slice}, {spoke});

		return existing;
	}

	testRecordChanges(slice: number, spoke = this.defaultSpokeName) {
		this.emit('slice-record-changes-test', {slice}, {spoke});
	}

	offRecordChanges(slice: number, spoke = this.defaultSpokeName) {
		console.log('@@TODO stop listening to record changes...', slice, spoke);
	}

	offCommentChanges(slice: number, spoke = this.defaultSpokeName) {
		console.log('@@todo stop listening to comment changes', slice, spoke);
	}

	offSliceChanges(slice: number, spoke = this.defaultSpokeName) {
		console.log('@@todo stop listening to slice changes', slice, spoke);
	}

	private _onSliceChanges(evt: {spoke: string, slice: number, data: SliceChanges}) {

		const cache = this._onSliceChangesObservers,
			slices = cache[evt.spoke],
			existing = slices ? slices.get(evt.slice) : false;

		if (!evt.spoke) {
			debugger;
		}
		if (existing) {
			existing.next(evt.data)
		} else {
			this.offSliceChanges(evt.slice, evt.spoke);
		}
	}

	private _onCommentChanges(evt: {spoke: string, slice: number, data: SliceCommentChanges}) {
		const cache = this._onCommentChangesObservers,
			slices = cache[evt.spoke],
			existing = slices ? slices.get(evt.slice) : false;
		if (existing) {
			existing.next(evt.data);
		} else {
			this.offCommentChanges(evt.slice, evt.spoke);
		}
	}

	private _onRecordChanges(evt: {spoke: string, slice: number, data: SliceRecordChanges}) {
		const cache = this._onRecordChangesObservers,
			slices = cache[evt.spoke],
			existing = slices ? slices.get(evt.slice) : false;
		if (existing) {
			existing.next(evt.data);
		} else {
			this.offRecordChanges(evt.slice, evt.spoke);
		}
	}

}
