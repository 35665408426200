export const host = `
--bg: #4c4cca;
--primary: #4c4cca;
--primary-fore: #fff;
--accent: #F450A7;
--accent-fore: #fff;
--accent-dark: #00000022;
--warn: #ff0000;
--warn-fore: #fff;
--disabled: #ddd;
--disabled-fore: #888;
--wrapper: #fff;
--wrapper-fore: #888;
--input: #fff;
--input-fore: #111;
--border-radius: 3px;
`;

// a dark profile
// export const host = `
// --bg: #666;
// --primary: #ff9900;
// --primary-fore: #fff;
// --accent: #32c832;
// --accent-fore: #fff;
// --accent-dark: #ffffff22;
// --warn: #ff0000;
// --warn-fore: #fff;
// --disabled: #ddd;
// --disabled-fore: #888;
// --wrapper: #000000b8;
// --wrapper-fore: #eee;
// --input: #fff;
// --input-fore: #111;
// --border-radius: 3px;
// `;