import { Api } from "./Api";
import { Auth } from "./Auth";
import { Templated } from "./Templated";
import { Subject, of } from "rxjs";
import { host } from './host';
import { SettingRow, SETTINGS } from "./Setting";
import { take, filter, catchError } from "rxjs/operators";

const MARGIN =  24,
	EMAIL = 'email',
	SMS = 'sms',
	VOICE = 'voice',
	VALIDATION_SENT = 'A validation code has been sent via a text message to the mobile # we have on file for you.',
	VALIDATION_SENT_VOICE = 'A call is being made to the mobile # on file for you.',
	SEND_INSTRUCTIONS = 'Send Instructions',
	TEXT_INSTRUCTIONS = 'Send Code',
	PHONE_INSTRUCTIONS = 'Call Now',
	LABELS = {
		[EMAIL]: SEND_INSTRUCTIONS,
		[SMS]: TEXT_INSTRUCTIONS,
		[VOICE]: PHONE_INSTRUCTIONS,
	};

export class Login extends Templated {

	constructor(
		private _api: Api,
		private _auth?: Auth,
	) {
		super({
			style: `
:host { ${host} }
:host,
:host * {
	box-sizing: border-box;
}
.displayNone {
	display: none;
}
.container {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: #fff;
	z-index: 99998;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto 1fr;
	background-color: var(--bg);
	background-repeat: no-repeat;
	background-size: cover;
	color: #222;
	overflow-x: hidden;
	overflow-y: auto;
}
.container, button, input {
	font-size: 14px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
button, input {
	outline: 0;
}
.header {
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
}
.footer,
.header {
	display: flex;
	color: var(--primary-fore);
}
.header {
	align-self: flex-end;
	padding: ${MARGIN}px;
}
.footer {
	align-self: flex-start;
	padding-top: ${MARGIN}px;
}
.wrapper {
	background-color: var(--wrapper);
	color: var(--wrapper-fore);
	padding: ${MARGIN}px ${MARGIN / 2}px;
	display: flex;
	justify-content: center;
	position: relative;
}
.form {
	max-width: 300px;
	width: 100%;
	margin: 0;
	position: absolute;
	left: -100vw;
	display: none;
}
.form.active {
	position: relative;
	left: unset;
	display: block;
}
.row {
	padding-bottom: ${MARGIN / 2}px;
}
.row:last-child {
	padding-bottom: 0;
}
.input label {
	display: block;
	padding: 0 4px 2px 0;
}
.input.active label {
	color: var(--primary);
}
.input input:not([type="radio"]) {
	width: 100%;
	padding: 12px 9px 9px 9px;
	margin: 1px;
	border: 1px solid #ddd;
	border-radius: var(--border-radius);
	background-color: var(--input);
	color: var(--input-fore);
}
.input.active input:not([type="radio"]) {
	margin: 0;
	border-width: 2px;
	border-color: var(--primary);
}
button {
	border: 0;
	background: 0;
	color: var(--primary);
	cursor: pointer;
	padding: 10px 22px;
	border-radius: var(--border-radius);
	border-width: 2px;
	border-style: solid;
	border-color: #ffffff00;
}
button.accent {
	background-color: var(--accent);
	color: var(--accent-fore);
	border-color: var(--accent);
}
button.accent:focus {
	border-color: var(--accent-dark);
}
button[disabled] {
	background-color: var(--disabled);
	color: var(--disabled-fore);
	cursor: not-allowed;
	border-color: var(--disabled);
}
#company {
	font-size: 32px;
	margin: 0;
	text-align: center;
}
.buttons {
	margin-top: 8px;
}
#sessionExpired {
	margin-bottom: 8px;
}
.radio {
	display: grid;
	grid-template-columns: min-content 1fr;
	align-items: center;
	gap: ${MARGIN / 3}px;
}
.radio label .secondary {
	display: block;
	font-size: 13px;
	opacity: 0.9;
}
.radio input {
	margin: 0;
}
.radio.input +.input {
	margin: ${MARGIN / 2}px 0;
}
`,
			tag: 'api-login',
			template: `
<div class="container">
	<div class="header">
		<div class="logo"></div>
		<h3 id="company"></h3>
	</div>
	<div class="wrapper">

		<form class="form active" id="loginForm" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
			<div class="row input" id="sessionExpired" style="display:none">
				Your session has expired, please enter your password to continue, or <a id="changeUser" href="javascript:void(0)">login as a different user.</a>
			</div>
			<div class="row input" id="loginWrapper">
				<label for="login">Login</label>
				<input type="text" id="login" value="" autocomplete="username email" autocorrect="off" autocapitalize="off" spellcheck="false">
			</div>
			<div class="row input">
				<label for="password">Password</label>
				<input type="password" id="password" value="" autocomplete="current-password" autocorrect="off" autocapitalize="off" spellcheck="false">
				<span class="password-toggle-icon"><i class="fas fa-eye"></i></span>
			</div>
			<div class="row">
				<input type="checkbox" id="showPassword" name="showPassword"> 
				<label for="showPassword">Show Password</label>
			</div>
			<div class="row buttons">
				<button type="submit" disabled class="accent">Login</button>
				<button type="button" id="forgot">Forgot Password</button>
			</div>
		</form>

		<form class="form" id="forgotForm">
			<div class="row input">
				<label for="forgotLogin">Login</label>
				<input type="text" id="forgotLogin" value="" autocomplete="username email" autocorrect="off" autocapitalize="off" spellcheck="false">
			</div>
			<div class="radio input">
				<input type="radio" id="recoverViaEmail" name="recoverVia" value="${EMAIL}" checked>
				<label for="recoverViaEmail">
					Email the recovery instructions
				</label>
			</div>
			<div class="radio input">
				<input type="radio" id="recoverViaSms" name="recoverVia" value="${SMS}">
				<label for="recoverViaSms">
					Text message me a recovery code
				</label>
			</div>
			<div class="radio input">
				<input type="radio" id="recoverViaVoice" name="recoverVia" value="${VOICE}">
				<label for="recoverViaVoice">
					Call me with a recovery code
				</label>
			</div>
			<div class="row buttons">
				<button type="submit" class="accent" disabled id="sendInstructions">${SEND_INSTRUCTIONS}</button>
				<button type="button" id="back">Back</button>
			</div>
		</form>

		<form class="form" id="smsForm">
			<p id="multiFactorMessage"></p>
			<div class="row input">
				<label for="smsCode">Validation Code</label>
				<input type="text" id="smsCode" value="" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
			</div>
			<div class="row buttons">
				<button type="submit" class="accent" disabled>Confirm</button>
				<button type="button" id="smsBack">Back</button>
			</div>
		</form>

		<form class="form" id="newPasswordForm">
			<div class="row input">
				<p id="codeSentText">${VALIDATION_SENT}</p>
				<label for="smsCode">Validation Code</label>
				<input type="text" id="newPasswordSmsCode" value="" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
			</div>
			<div class="row input">
				<label for="password">New Password</label>
				<input type="password" id="newPassword" value="" autocomplete="new-password" autocorrect="off" autocapitalize="off" spellcheck="false">
			</div>
			<div class="row input">
				<label for="password">Confirm New Password</label>
				<input type="password" id="newPasswordConfirm" value="" autocomplete="new-password" autocorrect="off" autocapitalize="off" spellcheck="false">
			</div>
			<div class="row buttons">
				<button type="submit" id="newpasswordSubmit" class="accent" disabled>Confirm</button>
				<button type="button" id="newpasswordBack">Back</button>
			</div>
		</form>

	</div>
	<div class="wrapper-footer"></div>
	<div class="footer">
		<div class="app-store-links">
			<!-- @@todo -->
		</div>
	</div>
</div>`
		});
	}

	private _afterShow: Subject<Auth>;
	private _allowGuest = false;
	private _resumeToken: string;
	private _nodes: {
		form: HTMLFormElement,
		forgotForm: HTMLFormElement,
		submit: HTMLButtonElement,
		login: HTMLInputElement,
		password: HTMLInputElement,
		forgot: HTMLButtonElement,
		forgotSubmit: HTMLButtonElement,
		forgotLogin: HTMLInputElement,
		sendInstructions: HTMLButtonElement,
		back: HTMLButtonElement,
		company: HTMLElement,
		smsForm: HTMLFormElement,
		smsCode: HTMLInputElement,
		smsSubmit: HTMLButtonElement,
		smsBack: HTMLButtonElement,
		recoverViaEmail: HTMLInputElement, // radio buttons
		recoverViaSms: HTMLInputElement, // radio buttons
		recoverViaVoice: HTMLInputElement,
		newPasswordForm: HTMLFormElement,
		newPasswordSmsCode: HTMLInputElement,
		newPassword: HTMLInputElement,
		newPasswordConfirm: HTMLInputElement,
		newpasswordSubmit: HTMLButtonElement,
		newpasswordBack: HTMLButtonElement,
	};
	private _multifactorConfirmBase: {login: string, password: string, requirePasswordSet?: boolean};

	private _assertionFn: (auth: Auth) => boolean;
	private _assertionFailMessage: string;
	private _assertionContext: any;

	getFormValue() {
		const n = this._nodes,
			login = (n.login.value || '').trim(),
			password = n.password.value;
		return {login, password};
	}

	getIsFormValid() {
		const v = this.getFormValue(),
			resumeToken = this._resumeToken;
		return !!(v && (v.login || !!resumeToken) && v.password);
	}

	show(allowGuest?: boolean, resumeSession?: {token: string, username: string}, assertionFn?: (auth: Auth) => boolean, assertionFailMessage?: string, assertionContext?: any ) {

		// quickly establish our assertion stuff...
		this._assertionFn = assertionFn || null;
		this._assertionFailMessage = assertionFailMessage || null;
		this._assertionContext = assertionContext || null;

		const settings = this._api.settings.getValue();

		if (!this.domNode.parentNode) {

			this._init();

			document.body.appendChild(this.domNode);

			const n = this._nodes;
			if (this.getIsFormValid) {
				n.submit.focus();
			} else {
				const v = this.getFormValue();
				if (v.login) {
					n.password.focus();
				} else {
					n.login.focus();
				}
			}

		}
		if (!this._afterShow) {
			this._afterShow = new Subject();
			this._allowGuest = allowGuest;
		}
		if (!resumeSession) {
			if (!settings && this._allowGuest) {
				this._api
					.report<SettingRow>('settings')
					.subscribe(resp => this._loadSettings(resp.rows))
			} else {
				this._loadSettings(settings || []);
			}
		} else {
			return this._resumeSession(resumeSession.token, resumeSession.username);
		}

		return this._afterShow;
	}

	private _resumeSession(token: string, username: string) {

		const sessNode = this.getElementById('sessionExpired'),
			loginNode = this.getElementById('loginWrapper'),
			loginInput = this.getElementById('login') as HTMLInputElement,
			link = this.getElementById('changeUser');

		sessNode.style.display = 'block';
		loginNode.style.display = 'none';
		loginInput.value = username;

		link.onclick = evt => {
			this._api.logout();
			location.reload();
		}

		return this._afterShow
			.pipe(
				filter(a => !!a),
				take(1),
			)
	}


	private _loadSettings(settings: SettingRow[] = []) {

		const bgImage = settings.find(s => s.key === 'login_background_image'),
			logo = settings.find(s => s.key === 'login_logo'),
			container = this.querySelector('.container'),
			header = this.querySelector('.header'),
			cssVars = settings.find(s => s.key === 'login_css_vars'),
			style = this.querySelector('style');

		console.warn('loading settings', {settings, bgImage, logo, container, header});

		if (cssVars && cssVars.val && style) {
			style.innerText += `:host { ${cssVars.val} }`;
		}
	
		if (bgImage && bgImage.val && container) {
			container.style.backgroundImage = `url("${bgImage.val}")`;
		}

		if (logo && logo.val && header) {
			header.innerHTML = `<img src="${logo.val}">`;
		}
	}

	hide() {

		const d = this.domNode,
			n = this._nodes,
			f = [n.form, n.forgotForm, n.smsForm, n.newPasswordForm].filter(n => !!n);	

		if (d.parentNode) {
			d.parentNode.removeChild(d);
		}

		f.forEach(x => x.reset());
		this._gotoLogin();
	}

	submit() {
		const n = this._nodes,
			val = this.getFormValue();

		if (this.getIsFormValid()) {
			n.submit.disabled = n.login.disabled = n.password.disabled = true;
			// which call are we making?
			this._api
				.login(val.login, val.password, undefined)
				.pipe(
					catchError(err => {
						console.warn('unexpected error response, treating as bad username/password', err);
						return of(null);
					}),
				)
				.subscribe(a => {
					n.submit.disabled = n.login.disabled = n.password.disabled = false;
					if (a) {
						if (a === 'SMS') {
							this._gotoSmsConfirm(val.login, val.password);
						} else if (a === 'GOOGLE_AUTHENTICATOR') {
							this._gotoGoogleConfirm(val.login, val.password);
						} else if (this._allowGuest || !a.guest) {
							if (this._assertionFn && !this._assertionFn.call(this._assertionContext || this, a)) {
								this._api.notify.warn(this._assertionFailMessage, 3000);
								return;
							}
							this._afterShow.next(a);
							this.hide();
						} else {
							this._api.notify.warn('This application requires an active, non-guest account');
						}
					} else {
						this._api.notify.warn('Invalid credentials.  Please double check and try again.');
					}
				});
		}
		return false;
	}

	forgotSubmit() {
		const n = this._nodes,
			login = n.forgotLogin.value,
			recoverVia = new FormData(this._nodes.forgotForm).get('recoverVia');

		if (login) {
			n.forgotSubmit.disabled = true;
			switch (recoverVia) {
				case EMAIL:
					this._forgotSubmitEmail(login);
					break;
				case SMS:
					this._forgotSubmitSms(login);
					break;
				case VOICE:
					this._forgotSubmitSms(login, true);
				default:
					console.warn('unhandled recoverVia', {login, recoverVia});
			}
			
		}
		return false;
	}
	forgotValidCheck() {
		const v = (this._nodes.forgotLogin.value || '').trim();
		this._nodes.forgotSubmit.disabled = !v;
	}

	// $user, $pin, $password)
	private _newPasswordFormSubmit() {

		const n = this._nodes,
			codeDisabled = !!(n.newPasswordSmsCode.disabled),
			code =  n.newPasswordSmsCode.value,
			pass = n.newPassword.value,
			login = n.forgotLogin.value,
			but = n.newpasswordSubmit;

		but.disabled = true;

		this._api
			.updatePasswordFromRecovery(login, pass, code)
			.subscribe(auth => {
				if (auth) {
					this._afterShow.next(auth);
					this.hide();
					this._api.notify.inform('Your password has been changed!');
				} else {
					but.disabled = false;
				}
			});
		return false;
	}

	private _forgotSubmitSms(login: string, voice = false) {
		this._api
			.sendRescueSms(login, voice)
			.subscribe(r => {
				if (r) {
					this._nodes.forgotSubmit.disabled = false;
					this._gotoSmsConfirm(login, '', true, voice);
				} else {
					alert('Unable to find that account');
				}
			})
	}

	private _forgotSubmitEmail(login: string) {
		const n = this._nodes;
		this._api
			.sendRescueEmail(login)
			.subscribe(r => {
				n.forgotSubmit.disabled = false;
				if (r) {
					this._gotoLogin();
					n.login.value = login;
					n.forgotLogin.value = '';
					this._formChanged();
					n.password.focus();
					this._api.notify.inform('An email was send with instructions to the address on file');
				} else {
					alert('Unable to find that account');
				}
			})
	}

	private _init() {
		
	  
		const node = this.domNode,
			a = this._auth,
			settings = this._api.settings.getValue(),
			client = settings?.find(s => s.key === SETTINGS.COMPANY_NAME)?.val || a?.company || this._api.spoke,
			nodes = this._nodes = {
				form: this.querySelector('#loginForm') as HTMLFormElement,
				forgotForm: this.querySelector('#forgotForm') as HTMLFormElement,
				submit: this.querySelector('#loginForm button[type="submit"]') as HTMLButtonElement,
				forgot: this.querySelector('button#forgot') as HTMLButtonElement,
				login: this.querySelector('input#login') as HTMLInputElement,
				password: this.querySelector('input#password') as HTMLInputElement,
				back: this.querySelector('#forgotForm button#back') as HTMLButtonElement,
				forgotSubmit: this.querySelector('#forgotForm button[type="submit"]') as HTMLButtonElement,
				forgotLogin: this.querySelector('#forgotLogin') as HTMLInputElement,
				sendInstructions: this.querySelector('#sendInstructions') as HTMLButtonElement,
				company: this.querySelector('#company') as HTMLElement,
				smsForm: this.querySelector('#smsForm') as HTMLFormElement,
				smsCode: this.querySelector('#smsForm #smsCode') as HTMLInputElement,
				smsSubmit: this.querySelector('#smsForm button[type="submit"]') as HTMLButtonElement,
				smsBack: this.querySelector('#smsForm button#smsBack') as HTMLButtonElement,
				recoverViaEmail: this.querySelector('#recoverViaEmail') as HTMLInputElement,
				recoverViaSms: this.querySelector('#recoverViaSms') as HTMLInputElement,
				recoverViaVoice: this.querySelector('#recoverViaVoice') as HTMLInputElement,
				newPasswordForm: this.querySelector('#newPasswordForm') as HTMLFormElement,
				newPasswordSmsCode: this.querySelector('#newPasswordSmsCode') as HTMLInputElement,
				newPassword: this.querySelector('#newPassword') as HTMLInputElement,
				newPasswordConfirm: this.querySelector('#newPasswordConfirm') as HTMLInputElement,
				newpasswordBack: this.querySelector('#newpasswordBack') as HTMLButtonElement,
				newpasswordSubmit: this.querySelector('#newpasswordSubmit') as HTMLButtonElement,
			};

		if (nodes?.company) {
			nodes.company.innerText = client;
		}

		const showPasswordCheckbox = nodes.form.querySelector('#showPassword') as HTMLInputElement;
		showPasswordCheckbox.addEventListener('change', this._togglePasswordVisibility.bind(this));
		
		nodes.form.onsubmit = () => this.submit();

		nodes.forgotForm.onsubmit = () => this.forgotSubmit();
		nodes.forgotLogin.oninput = () => this.forgotValidCheck();
		
		Array.from(
			this.querySelectorAll('input')
		).forEach(input => {
			input.onfocus = this._inputFocus;
			input.onblur = this._inputBlur;
		});

		nodes.forgot.onclick = () => this._gotoForgot();
		nodes.back.onclick = () => this._gotoLogin();

		nodes.login.oninput = nodes.password.oninput = () => this._formChanged();
		nodes.login.addEventListener('input', () => {
			nodes.forgotLogin.value = nodes.login.value;
			this.forgotValidCheck();
		});

		[nodes.recoverViaEmail, nodes.recoverViaSms, nodes.recoverViaVoice]
			.forEach(e => e.addEventListener('change', x => {
				if (e.value in LABELS) {
					this._nodes.sendInstructions.innerText = (LABELS as any)[e.value];
				}
			}))

		setTimeout(() => {
			if (this.getIsFormValid()) {
				this._nodes.submit.disabled = false;
			}
		})

		nodes.smsCode.oninput = () => {
			const len = ((nodes.smsCode.value || '') + '').trim().length;
			nodes.smsSubmit.disabled = !len;
		}
		nodes.smsForm.onsubmit = () => this._submitSms();
		nodes.smsBack.onclick = () => this._gotoLogin();

		nodes.newPasswordForm.onsubmit = () => this._newPasswordFormSubmit();
		nodes.newpasswordBack.onclick = () => this._gotoForgot();

		[nodes.newPasswordSmsCode, nodes.newPassword, nodes.newPasswordConfirm]
			.forEach(n => n.addEventListener('input', () => this._newPasswordFormStateChanged()));
		
		// listen to settings changed and update the ui accordingly
		this._api
			.settings
			.pipe(
				filter(r => !!r?.length)
			)
			.subscribe(rows => {
				const name = rows.find(r => r.key === SETTINGS.COMPANY_NAME)?.val,
					style = rows.find(r => r.key === SETTINGS.LOGIN_CSS)?.val;
				if (name) {
					this._nodes.company.innerText = name;
				}
				if (style) {
					this.styleNode.innerHTML += style;
				}
			});
	}

	private _formChanged() {
		this._nodes.submit.disabled = !this.getIsFormValid();
	}

	private _inputFocus(evt: FocusEvent) {
		const n = (evt.target as HTMLElement).closest('.input');
		if (n) {
			n.classList.add('active');
		}
	}

	private _togglePasswordVisibility() {
		const passwordInput = this._nodes.form.querySelector('#password') as HTMLInputElement;
		if (passwordInput.type === "password") {
		  passwordInput.type = "text";
		} else {
		  passwordInput.type = "password";
		}
	  }

	private _inputBlur(evt: FocusEvent) {
		const n = (evt.target as HTMLElement).closest('.input');
		if (n) {
			n.classList.remove('active');
		}
	}

	private _gotoForgot() {
		const nodes = this._nodes;
		this._toggleActiveForm(nodes.forgotForm);
		(nodes.forgotForm.elements[0] as HTMLInputElement).focus();
	}
	private _gotoLogin() {
		const nodes = this._nodes;
		this._toggleActiveForm(nodes.form)
		nodes.login.focus();
	}

	private _gotoSmsConfirm(login: string, password: string, requirePasswordSet = false, voice = false) {
		this.getElementById('multiFactorMessage').innerText = VALIDATION_SENT;
		if (requirePasswordSet) {
			this._gotoNewPassword(true, voice);
		} else {
			this._gotoConfirm(login, password);
		}
		
	}

	private _gotoGoogleConfirm(login: string, password: string) {
		this.getElementById('multiFactorMessage').innerText = 'Please enter the code that is currently displaying for this site in your Google Authenticator app.';
		this._gotoConfirm(login, password);
	}

	private _newPasswordFormStateChanged() {
		const nodes = this._nodes,
			smsNode = nodes.newPasswordSmsCode,
			smsCode = (smsNode.value || ''),
			pass = (nodes.newPassword.value || ''),
			newP = (nodes.newPasswordConfirm.value || ''),
			valid = (
				(smsNode.disabled || smsCode.length)
				&& (pass && pass.length)
				&& (newP && newP.length)
				&& pass === newP
			),
			sub = nodes.newpasswordSubmit;

		if (!valid !== sub.disabled) {
			sub.disabled = !valid;
		}
	}

	private _gotoNewPassword(needsSmsCode?: boolean, wasVoice = false) {
		const nodes = this._nodes,
			form = nodes.newPasswordForm,
			smsCode = nodes.newPasswordSmsCode,
			par = smsCode.parentElement,
			codeSentText = this.getElementById('codeSentText');

		this._toggleActiveForm(form);
		form.classList.add('active');

		codeSentText.innerText = wasVoice ? VALIDATION_SENT_VOICE : VALIDATION_SENT;

		if (needsSmsCode) {
			smsCode.parentElement.classList.remove('displayNone');
			smsCode.disabled = false;
			smsCode.focus();
		} else {
			smsCode.parentElement.classList.add('displayNone');
			smsCode.disabled = true;
			nodes.newPassword.focus();
		}
	}

	private _gotoConfirm(login: string, password: string, requirePasswordSet = false) {
		const nodes = this._nodes;
		this._multifactorConfirmBase = {login, password, requirePasswordSet};
		this._toggleActiveForm(nodes.smsForm);
		nodes.smsForm.classList.add('active');
		nodes.smsCode.focus();
	}

	private _toggleActiveForm(makeActive: HTMLFormElement) {
		const n = this._nodes,
			x = 'active';
		[n.form, n.smsForm, n.forgotForm, n.newPasswordForm]
			.forEach(f => {
				if (f === makeActive && !f.classList.contains(x)) {
					f.classList.add(x);
				} else if (f !== makeActive && f.classList.contains(x)) {
					f.classList.remove(x);
				}
			})
	}

	private _submitSms() {

		const n = this._nodes,
			sub = n.smsSubmit,
			code = n.smsCode.value,
			base = this._multifactorConfirmBase;

		sub.disabled = true;

		this._api
			.login(base.login, base.password, code)
			.subscribe(a => {
				sub.disabled = false;
				if (a && a !== 'SMS' && a !== 'GOOGLE_AUTHENTICATOR') {
					this._afterShow.next(a);
					this.hide();
				} else {
					this._api.notify.warn('The validation code you provided does not match our records.  Please double check to ensure you input the exact value.');
				}
			})
		return false;
	}

}