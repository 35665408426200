import * as socketIO from 'socket.io-client';
import { BehaviorSubject } from "rxjs";

export abstract class _AuxiliumSocketClientBase {

	static CONNECTION_TIMEOUT_MS = 5000;

	protected socket = new BehaviorSubject<string | false>(false);
	private _socket: SocketIOClient.Socket;
	private _rid = 0;

	constructor(
		public path?: string,
	) {
		if (!path) {
			this.path = `https://REALM.auxiliumgroup.com:9394/`;
		}
		this._connect();
	}

	get defaultOptions(): SocketIOClient.ConnectOpts {
		const path = this.path,
			secure = path.indexOf('https://') === 0;
		return {
			secure,
			timeout: _AuxiliumSocketClientBase.CONNECTION_TIMEOUT_MS,
		}
	}

	protected emit(evtName: string, data: any, params: any = {}): void {
		if (params && params.requestId) delete params.requestId;
		this._socket.emit(evtName, data, params);
	}

	
	on(evtName: string, cb: (... args: any[]) => void, socket = this._socket) {
		return this._wrapOn(evtName, cb, socket, false);
	}
	

	once(evtName: string, cb: (args?: any) => void, socket = this._socket) {
		return this._wrapOn(evtName, cb, socket, true);
	}



	private _makeRequestId() {
		return (this._rid++) + '-' + new Date().getTime();
	}

	private _connect() {
		const path = this.path,
			socket = this._socket = socketIO(path, this.defaultOptions);
		this._attachListeners(socket);
	}

	private _attachListeners(sock: SocketIOClient.Socket) {
		sock.on('connect', () => {
			this.socket.next(sock.id);
		});

		sock.on('disconnect', () => {
			this.socket.next(false);
		});
	}


	disconnect() {
		const p = this._socket;
		if (p && p.connected) {
			p.close();
		}
	}

	connect() {
		const pub = this._socket;
		if (!pub) {
			this._connect();
		} else if (pub.disconnected) {
			pub.open();
		}
	}

	/**
	 * this is simply a wrapper function for on/once to return
	 * a way to 'stop' listening without having to micromanage it
	 */
	private _wrapOn(evtName: string, cb: (args?: any) => void, socket = this._socket, once?: boolean) {
		let cancelled = false,
			resolved = false,
			received = false,
			emit: SocketIOClient.Emitter;

		const wrapped = ((data: any) => {
				if (!cancelled) {
					cb(data);
					resolved = true;
				}
			}).bind(this);

		if (once) {
			emit = socket.once(evtName, wrapped)
		} else {
			emit = socket.on(evtName, wrapped);;
		}

		const ret = {
			get resolved() { return resolved },
			get cancelled() { return cancelled },
			get received() { return received },
			set received(rec: boolean) { received = rec },
			off: () => {
				cancelled = true;
				if (emit) {
					emit.off(evtName, wrapped);
				}
			}
		}
		return ret;
	}
}