import * as JsBarcode from 'jsbarcode';

export class Barcode {

	static instance(win = window) {
		let inst = Barcode._instance;
		if (!inst) {
			inst = Barcode._instance = new Barcode();
		}
		return inst;
	}
	static _instance: Barcode;

	static config: JsBarcode.Options| null = {
		height: 50,
		displayValue: false,
	};

	toCanvas(value: string, config: JsBarcode.Options = {}) {
		const canvas = document.createElement('canvas');
		JsBarcode(canvas, value, { ... Barcode.config, ... config});
		return canvas;
	}
	
}